import { paginationClassName } from "./MissionSlider";
import { commonSliderStyle } from "../HeroSlider/HeroSlider.theme";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const MissionSlider: ComponentStyleConfig = {
	baseStyle: () => ({
		wrapper: {
			...commonSliderStyle(paginationClassName),

			// otherwise, button is invisible on white missions; color is brand.light
			"& .swiper-button-disabled.swiper-button-disabled.swiper-button-disabled":
				{
					background: "rgba(208, 222, 235, 0.5)",
				},
		},
	}),
};

export default MissionSlider;
