import { figmaHelper } from "../../styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const EditableSection: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			paddingTop: 8,
		},
		header: {
			display: "flex",
			justifyContent: "space-between",
			paddingBottom: 2,
			borderBottom: "1px solid",
			borderColor: "brand.light",
		},
		content: {
			paddingY: 6,
		},
		editButton: {
			textDecoration: "none",
			textTransform: "lowercase",
			fontWeight: "semibold",
			...figmaHelper(12, 16),
		},
	},
};

export default EditableSection;
