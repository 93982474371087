import { listStyle } from "src/styles/mixins";
import { figmaHelper } from "../../styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

export const priceStyle = {
	...figmaHelper({ base: 34, lg: 42 }, { base: 46, lg: 75 }),
	fontStyle: "italic",
	fontWeight: "medium",
	fontFamily: "serif",
	letterSpacing: "0.02em",
	color: "brand.highlight",
};

const Product: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			display: "flex",
			justifyContent: "center",
			textAlign: "left",
			paddingBottom: 6,
			paddingX: { base: 0, lg: 3 },
			hyphens: "auto",
		},
		items: {
			display: "flex",
			flexDir: { base: "column", lg: "row" },
			justifyContent: "center",
			maxWidth: "container.xl",
			margin: "auto",
			paddingY: { base: 2, lg: 4 },
			flexWrap: "wrap",
		},
		inner: {
			display: "flex",
			flexDirection: "column",
			width: "full",
			maxWidth: "lg",
			bg: "white",
			boxShadow: "pop",
			borderRadius: "md",
			padding: { base: 6, lg: 10 },

			ul: listStyle,

			p: {
				...figmaHelper({ base: 14, lg: 16 }, { base: 21, lg: 26 }),
			},

			"a, button": {
				width: "full",
			},
		},
		startingPrice: {
			color: "brand.dark",
			display: "flex",
			alignItems: "center",
			gap: 2,
			minHeight: "4.7rem",
			...figmaHelper({ base: 14, lg: 16 }, { base: 21, lg: 26 }),

			strong: {
				...priceStyle,
			},
			marginBottom: "1rem",
		},
		heading: {
			hyphens: "auto",
			color: "brand.dark",
			paddingBottom: 3,
		},
		iconWrapper: {
			padding: "10px 20px 20px 0",
			alignSelf: "start",
		},
		icon: {
			width: { base: 16, md: 20 },
			height: { base: 16, md: 20 },
		},
	},
	variants: {
		center: {
			headingWrapper: {
				display: "flex",
				flexDir: "column",
			},
			iconWrapper: {
				alignSelf: "center",
				paddingRight: 0,
			},
			heading: {
				textAlign: "center",
			},
			startingPrice: {
				alignSelf: "center",
			},
		},
	},
};

export default Product;
