import { ChakraTheme, extendTheme } from "@chakra-ui/react";
import { components } from "src/__generated__/components";
import withdrawPolicyTheme from "src/components/WithdrawPolicyModal/WithdrawPolicy.theme";
import { globalStyles } from "./globalStyles";
import { SIZES_CONTAINER_XL, SPACE_GRID_GAP } from "./themeConstants";
import { pxToRem } from "./themeUtils";

export const backgroundGradient =
	"radial-gradient(50% 600% at 50% 50%, var(--chakra-colors-brand-dark) 31.33%, var(--chakra-colors-brand-darker) 100%)";

/**
 * TODO: 🚧 this is a really bad idea and we should get rid of it:
 *
 * If someone uses a non-chakraized value e.g. `width=123` chakra
 * will assume 123px = 7.6875rem. If someone else later adds
 * `123: "30.75rem"` to this object, the old value will be overwritten
 * and thus be way too big.
 *
 * Don't use this, if you find time, refactor it away:
 */
const customSizes = {
	4.5: "1.125rem",
	13: "3.25rem",
	18: "4.5rem",
	22: "5.5rem",
	42: "10.5rem",
	76: "19rem",
};

export const funkThemeColors = {
	red: {
		// this override ensures we use our red for all erroring UI
		500: "#c66262",
	},
	gray: {
		medium: "#D9D9D9",
		dark: "#333333",
	},
	brand: {
		light: "#D0DEEB",
		lighter: "#F4F6FA",
		"lighter.translucent": "rgba(244, 246, 250, 0.5)",
		medium: "#6F89A4",
		dark: "#005092",
		"dark.disabled": "rgba(25, 100, 197, 0.5)",
		"dark.translucent": "rgba(25, 100, 197, 0.12)",
		darker: "#021B47",
		highlight: "#51C519",
		"highlight.hover": "#40ad30",
		"highlight.disabled": "rgba(81, 197, 25, 0.5);",
		"highlight.faint": "rgba(81, 197, 25, 0.25);",
	},
	status: {
		purple: "#C1BEDF",
		blue: "#AAC8E9",
		green: "#52C51A80",
		red: "#FAAFB0",
		yellow: "#FFCD2980",
		gray: "#E0E0E0",
		orange: "#F7C5A6",
	},
	shadows: {
		pop: "rgba(8, 26, 45, 0.16)",
		drop: "rgba(0, 0, 0, 0.1)",
		text: "rgba(0, 0, 0, 0.25)",
	},
	ui: {
		error: "#c66262",
		focus: "#51C519",
		loading: "#40ad30",
		skeleton: "#9eafc2",
		inactive: "#999999",
	},
} as const;

export const funkTheme: Partial<ChakraTheme> = {
	breakpoints: {
		xs: "25em",
		desktopNavigation: "71em",
	},
	styles: {
		global: {
			...globalStyles,
		},
	},
	sizes: {
		...customSizes,
		// container sizes are the one place chakra uses pixels
		container: {
			xs: "400px",
			smaller: "480px",
			contactSection: "970px",
			xl: `${SIZES_CONTAINER_XL}px`,
		},
	},
	space: {
		...customSizes,
		grid: {
			gap: pxToRem(SPACE_GRID_GAP),
		},
	},
	fonts: {
		serif: `'Noto Serif', serif`,
		sans: `'Noto Sans', sans-serif`,

		// Used by Chakra by these names
		heading: `'Noto Serif', serif`,
		body: `'Noto Sans', sans-serif`,
	},
	colors: funkThemeColors,
	shadows: {
		drop: `0px 4px 12px var(--chakra-colors-shadows-drop)`,
		textDrop: `0px 4px 4px var(--chakra-colors-shadows-text)`,
		dropWide: "10px 10px 60px 0px var(--chakra-colors-shadows-drop)",
		pop: `0 1rem 2rem -0.2rem var(--chakra-colors-shadows-pop)`,
		highlight: `0 1rem 2rem -0.2rem var(--chakra-colors-shadows-pop)`,
		focusNoPadding: `0 0 0 0.2rem var(--chakra-colors-brand-highlight)`,
		carousel: "0px 16px 32px -3px rgba(11, 50, 90, 0.25)",
		radioCard:
			"0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04)",
		// This is the one chakra uses for all :focus styles
		outline: `0 0 0 0.25rem white, 0 0 0 0.45rem var(--chakra-colors-brand-highlight)`,
	},
	semanticTokens: {
		colors: {
			"chakra-body-text": { _light: "#021B47" },
		},
	},
	components: {
		...components,
		// Both modals currently share the same styles
		CancelPolicy: withdrawPolicyTheme,
	},
};

const theme = extendTheme(funkTheme);

export default theme;

// 🔬 Story for relevant theme properties (colors) to be displayed
