import { figmaHelper } from "../../styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const FaqSection: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			bg: "brand.lighter",
			paddingTop: {
				base: 8,
				md: 20,
			},
			paddingBottom: {
				base: 12,
				md: 24,
			},
		},
		titleContainer: {
			paddingBottom: {
				base: 2,
				lg: 6,
			},
		},
		title: {
			textAlign: "center",
			fontFamily: "sans",
			color: "brand.dark",
			...figmaHelper({ base: 30, lg: 42 }, { base: 31, lg: 52 }),
		},
		subtitle: {
			paddingTop: { base: 8, lg: 16 },
			pb: 5,
			fontWeight: "medium",
			textAlign: "center",
			color: "brand.dark",
			fontSize: {
				base: 20,
				lg: 30,
			},
		},
		text: {
			textAlign: "center",
		},
		product: {
			...figmaHelper({ base: 16, lg: 30 }, { base: 20, lg: 25 }),
			color: "brand.dark",
			pt: { base: 5, lg: 10 },
			fontFamily: "sans",
		},
		targetGroup: {
			...figmaHelper({ base: 14, lg: 24 }, { base: 20, lg: 32 }),
			pt: 10,
			display: "inline-block",
			fontWeight: "semibold",
		},
		input: {
			border: "1px solid",
			bg: "white",
			_focus: {
				bg: "white",
				outline: "2px solid",
				outlineColor: "brand.highlight",
			},
			mt: 5,
		},
		topicSelector: {
			alignItems: { base: "start", lg: "center" },
			overflowX: "auto",
			w: "full",
			px: 4,
			py: 5,
		},
	},
};

export default FaqSection;
