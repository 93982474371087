import { paginationClassName } from "./HeroSlider";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

export const commonSliderStyle = (paginationClassName: string) => ({
	position: "relative",

	[`.${paginationClassName}`]: {
		textAlign: "center",
		position: "absolute",
		bottom: -6,
	},

	"& .swiper-slide": {
		height: "auto",

		"> div": {
			height: "full",

			"> div:first-of-type": {
				marginTop: { base: "auto", lg: "unset" },
			},
		},
	},
});

const HeroSlider: ComponentStyleConfig = {
	baseStyle: () => ({
		wrapper: {
			...commonSliderStyle(paginationClassName),
		},
	}),
};

export default HeroSlider;
