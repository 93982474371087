import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Carousel: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			position: "relative",
			"& .swiper-slide": {
				textAlign: "center",
				width: {
					base: "280px",
					md: "360px",
				},
				height: "auto",
				backgroundColor: "brand.lighter",
				boxShadow: "carousel",
				opacity: 0.4,
				transition: "opacity 0.5s",
			},
			"& .swiper-slide-active": {
				opacity: 1,
			},
			"& .swiper-slide-next, .swiper-slide-prev": {
				opacity: {
					base: 0.4,
					lg: 1,
				},
			},
		},
	},
};

export default Carousel;
