import { ComponentStyleConfig } from "@chakra-ui/react";

const Blogpost: ComponentStyleConfig = {
	baseStyle: ({ breakpoint: bp }) => ({
		wrapper: {
			maxW: "container.xl",
			display: "grid",
			gridTemplateColumns: { base: "1fr", [bp]: "1fr 4fr" },
			gap: { base: 8, [bp]: 16 },
			paddingTop: 12,
			paddingBottom: 12,
			color: "brand.dark",
			justifyContent: "center",
		},
		metadata: {
			justifySelf: { base: "center", [bp]: "end" },
			display: "flex",
			flexDir: "column",
			fontSize: "sm",
			fontWeight: 600,
		},
		title: {
			fontFamily: "sans",
			fontWeight: 600,
			paddingBottom: 8,
			textAlign: { base: "center", [bp]: "left" },
		},
		text: {
			paddingBottom: 8,

			h2: { fontSize: "2xl" },
			h3: { fontSize: "xl" },
		},
		topicLink: {
			color: "white",
			fontWeight: 600,
			padding: "0.5rem 1rem",
			borderRadius: "xl",
			boxShadow: "drop",
			textDecoration: "none",
			maxW: "fit-content",
		},
		topicWrapper: {
			display: "flex",
			flexDir: "column",
			width: "fit-content",
			alignSelf: { base: "center", [bp]: "start" },
		},
		bottomElements: {
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			flexDir: { base: "column", [bp]: "row" },
			gap: 6,
		},
		previewTitle: {
			fontSize: "xl",
			textAlign: "left",
			fontFamily: "sans",
			fontWeight: 600,
		},
		previewMetadata: {
			display: "flex",
			color: "brand.medium",
			fontSize: "sm",
		},
		previewTextWrapper: {
			padding: 4,
			display: "flex",
			flexDirection: "column",
			gap: 4,
			fontWeight: 500,
			height: "calc(100% - 10rem)",
		},
		previewImageWrapper: {
			position: "relative",
			height: "10rem",
		},
		previewWrapper: {
			boxShadow: "drop",
			borderRadius: "md",
			overflow: "hidden",
			textAlign: "left",
			bg: "white",
			color: "brand.dark",
		},
		socialLinks: {
			display: "flex",
			justifyContent: { base: "center", [bp]: "start" },
			gap: { base: 10, lg: 8 },
		},
		authorImage: {
			width: 13,
			height: 13,
			overflow: "hidden",
			borderRadius: "100vw",
		},
	}),
};

export default Blogpost;
