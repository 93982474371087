import { ComponentStyleConfig } from "@chakra-ui/react";

const ProductComparison: ComponentStyleConfig = {
	baseStyle: ({ introAlignment }) => ({
		wrapper: {
			bg: "brand.lighter",
			paddingTop: { base: 4, lg: 12 },
			textAlign: "center",
			color: "brand.darker",
		},
		blocks: {
			margin: "auto",
			paddingX: 4,
		},
		intro: {
			paddingY: 2,
			maxWidth: "container.lg",
			margin: "auto",
			textAlign: [introAlignment],

			"h1, h2, h3, h4, h5, h6": {
				maxW: "container.md",
				margin: "auto",
			},
		},
		items: {
			display: "flex",
			flexDir: { base: "column", xl: "row" },
			justifyContent: "center",
			maxWidth: "container.xl",
			margin: "auto",
			paddingY: { base: 2, lg: 4 },
		},
		carouselWrapper: {
			textAlign: [introAlignment],
			paddingTop: { base: 4, lg: 12 },
			color: "brand.darker",

			"& .swiper-wrapper": {
				marginY: 16,
			},
			// double class as a specificity hack
			"& .swiper-slide.swiper-slide": {
				boxShadow: "none",

				width: {
					base: "19rem",
					sm: "28rem",
					md: "32rem",
				},
			},

			"[data-structured-text-block=product]": {
				height: "100%",
			},
			bg: "brand.lighter",
		},
		carouselIntro: {
			paddingY: 2,
			paddingX: 4,
			maxWidth: "container.lg",
			margin: "auto",

			"h1, h2, h3, h4, h5, h6": {
				maxW: "container.md",
				margin: "auto",
			},
		},
	}),
};

export default ProductComparison;
